<template>
  <div class="article">
    <div class="content">
      <div v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import { httpAction } from "@/api/manage";
import action from "@/utils/constant";

export default {
  name: "isArticle",
  data() {
    return {
      detail: [{ content: "" }],
    };
  },
  mounted() {
    if (this.$route.query.typeCode) {
      this.getArticleList();
    } else {
      this.getArticleDetail();
    }
  },
  methods: {
    // 根据typeCode获取详情
    getArticleList() {
      httpAction(
        "/system/sysArticle/list",
        action.GET,
        {
          pageNo: 1,
          pageSize: 1,
          typeCode: this.$route.query.typeCode,
        },
        (res) => {
          console.log(res);
          this.detail = res.records[0];
        }
      );
    },
    // 根据id获取详情
    getArticleDetail() {
      httpAction(
        "/system/sysArticle/queryById",
        action.GET,
        {
          id: this.$route.query.id,
        },
        (res) => {
          console.log(res);
          this.detail = res.records;
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
.article {
  padding: 15px;
  .content{
    &/deep/img{
      max-width: 100%;
      float: left;
    }
  }
}
</style>
